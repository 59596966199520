import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {getPaymentHistory, hasError, isLoading} from '../../reducers/paymentHistoryReducer';
import {getMemberFirstName, getMemberLastName} from '../../reducers/memberDetailsReducer';
import {getPlanContractId, getPlanType} from '../../reducers/planSummaryReducers';
import {DateField} from '../commons/DateField';
import {FetchPanel} from '../commons/FetchPanel';
import Form from '../commons/Form';
import {PaymentHistoryRow} from './PaymentHistoryRow';
import {
  fetchPaymentHistory,
  paymentHistoryPrintClicked,
  paymentHistorySearchClicked
} from '../../actions/paymentSummary/FetchPaymentHistoryAction';
import Print from '../commons/Print';
import '../../styles/paymentHistory.css';
import ndcLogo from '../../images/NDC_Logo_white.png';
import Submit from '../commons/Submit';

export class PaymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateFilter: '',
      endDateFilter: '',
    };
  }
  componentDidMount() {
    this.props.fetchPaymentHistory();
  }

  onClick = () => this.props.fetchPaymentHistory(this.state.startDateFilter, this.state.endDateFilter);

  updateStartDateFilter = date => this.setState({ startDateFilter: date });

  updateEndDateFilter = date => this.setState({ endDateFilter: date });

  displayNoPaymentHistory = () => (
    <tr>
      <td colSpan="4" className="text-center">
        <h1 className="nohistory">There is no payment history</h1>
      </td>
    </tr>
  );

  submitDisabled = () => !this.state.startDateFilter || !this.state.endDateFilter;
  render() {
    return (
      <FetchPanel isLoading={this.props.isLoading} title="Payment History" hasError={this.props.hasError}>
        <div>
          <Row>
            <div className="pull-right">
              <img className="paymentHistoryLogo" src={ndcLogo} alt="New Dental Choice Logo" />
            </div>
          </Row>
          <div className="memberInfo">
            <Col>
              <span className="memberName">Member name: {this.props.memberFirstName} {this.props.memberLastName}</span>
            </Col>
            <Col>
              <span className="contractId">Membership ID: {this.props.planContractId}</span>
            </Col>
            <Col>
              <span className="type">Plan type: {this.props.planType}</span>
            </Col>
          </div>
          <div className="text-right print">
            <Print onClick={this.props.paymentHistoryPrintClicked} />
          </div>
          <br />
          <div className="panel panel-default search-panel">
            <div className="panel-body">
              <Form onSubmit={this.onClick}>
                <Row>
                  <div className="col-sm-6 col-md-6">
                    <label htmlFor="search">Search Payment History</label>
                  </div>
                </Row>
                <Row>
                  <div className="col-sm-5 col-md-5 col-xl-3">
                    <div className="form-group">
                      <DateField
                        className="startdate"
                        name="start"
                        date={this.state.startDateFilter}
                        update={this.updateStartDateFilter}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 col-md-5 col-xl-3">
                    <div className="form-group">
                      <DateField
                        className="enddate"
                        name="end"
                        date={this.state.endDateFilter}
                        update={this.updateEndDateFilter}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <Submit
                      isLoading={this.props.isLoading}
                      disabled={this.submitDisabled()}
                      onClick={this.props.paymentHistorySearchClicked}
                    >Search</Submit>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-body">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Payment Date</th>
                    <th>Payment Source</th>
                    <th>Payment Status</th>
                    <th>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.paymentHistory.map(transaction => <PaymentHistoryRow key={transaction.date} transaction={transaction} />)}
                  {this.props.paymentHistory.length === 0 && this.displayNoPaymentHistory()}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="contact">
            <Row>
              <Col>
                If you have any questions regarding these transaction(s) or about your New Dental Choice membership,
                please contact our Member Services Team toll-free at <strong>1-888-632-3676</strong>, Monday to Friday 8:00am to
                5:00pm PST. Our New Dental Choice website (www.newdentalchoice.com) provides convenient 24/7
                access to look up participating dentists and all the Plan details.
              </Col>
            </Row>
            <br />
            <Row>
              <strong>Our mailing address is:</strong>
            </Row>
            <Row>
              P.O. Box 919029
            </Row>
            <Row>
              San Diego, California 92121
            </Row>
            <Row className="copyright">
              <em>Copyright © {new Date().getFullYear()} New Dental Choice, All rights reserved.</em>
            </Row>
          </div>
        </div>
      </FetchPanel>
    );
  }
}

PaymentHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  paymentHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchPaymentHistory: PropTypes.func.isRequired,
  paymentHistorySearchClicked: PropTypes.func.isRequired,
  paymentHistoryPrintClicked: PropTypes.func.isRequired,
  memberFirstName: PropTypes.string.isRequired,
  memberLastName: PropTypes.string.isRequired,
  planContractId: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isLoading(state),
  hasError: hasError(state),
  paymentHistory: getPaymentHistory(state),
  memberFirstName: getMemberFirstName(state),
  memberLastName: getMemberLastName(state),
  planContractId: getPlanContractId(state),
  planType: getPlanType(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchPaymentHistory: (startDateFilter, endDateFilter) => {
    if (window.location.hostname.includes("principaldentalaccess")) {
      dispatch(fetchPaymentHistory({ startDateFilter, endDateFilter, subtype: "PDAP" }));
    } else {
      dispatch(fetchPaymentHistory({ startDateFilter, endDateFilter }));
    }
  },
  paymentHistorySearchClicked: () => dispatch(paymentHistorySearchClicked()),
  paymentHistoryPrintClicked: () => dispatch(paymentHistoryPrintClicked()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
